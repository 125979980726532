export default {
  INDEX: {
    title: "カレンダー",

    sub_title: "カレンダールール設定",

    breadcrumb: {
      dashboard: "ダッシュボード ",

      calendar: "カレンダー",
    },
    editmodal: {
      exclude_from_repetition: "繰り返しから除外",
      select_a_business_category: "営業区分を選択",
      delete_appointment: "予定を削除",
      regular_holiday: "定休日",
      temporary_holiday: "臨時休業日",
      special_business_day: "特別営業日",
      free_release_date: "無料公開日",
      there_is_a_notice: "お知らせあり",
    },
    grid: {
      rules_being_set: "設定中のルール",
      new_calendar_rule_settings: "新規カレンダールール設定",
      STEP1_Select_a_candidate: "STEP1.候補を選択",
      STEP2_Select_a_candidate: "STEP2.候補を選択",
      STEP3_Select_a_candidate: "STEP3.候補を選択",
      please_enter_from_STEP1_to_STEP3: "STEP1~STEP3まで入力してください",
      please_select: "選択してください",
    },
    select_day_order: {
      Weekly: "毎週 ",
      First_week_of_every_month: "毎月第1週",
      second_week_of_every_month: "毎月第2週 ",
      third_week_of_every_month: "毎月第3週 ",
      fourth_week_of_every_month: "毎月第4週 ",
      fifth_week_of_every_month: "毎月第5週 ",
      ten_th_day_of_the_month: "毎月第1〇曜日",
      twenty_th_day_of_the_month: "毎月第2〇曜日",
      thirty_th_day_of_the_month: "毎月第3〇曜日",
      fourty_th_day_of_the_month: "毎月第4〇曜日",
      fifty_th_day_of_the_month: "毎月第5〇曜日",
    },
    day: {
      sunday: "日曜日",
      monday: "月曜日",
      tuesday: "火曜日",
      Wednesday: "水曜日",
      thursday: "木曜日",
      friday: "金曜日",
      saturday: "土曜日",
    },
    button: {
      create: "施設スタッフ追加",

      update: "編集",

      show: "表示",

      delete: "削除",

      search: "検索",

      cancel: "キャンセル",

      execution: "実行",

      save: "保存",
      save_the_set_rules :"設定したルールを保存"
    },
    delete: {
      title: "確認",

      description: "アーカイブに移動しますか？",

      execution: "実行",

      cancel: "キャンセル",
    },
    search: {
      authority: "権限",
      un_select: "未選択",
      no_results: "検索結果はありません",
    },
    place_holder: {
      enter_the_content_of_the_notification: "お知らせの内容を入力",
    },
    staff_list: "スタッフ一覧",
    pagination_url: "user",
  },
};
